
//import Rails from "@rails/ujs"
require("./application");

import "@fortawesome/fontawesome-free/css/all";

import "../stylesheets/frontend.scss";



/* FRONT ASSETS */

//import "../vendor/bracket/lib/cookieconsent/dist/cookieconsent";

/*
import AOS from "../vendor/frontend/assets/vendor/aos/aos.js";
window.AOS = AOS;

import "../vendor/frontend/assets/vendor/glightbox/js/glightbox";
require("../vendor/frontend/assets/vendor/isotope-layout/isotope.pkgd.min.js");
import Swiper from  "../vendor/frontend/assets/vendor/swiper/swiper-bundle.min.js";
window.Swiper = Swiper;

require("../vendor/frontend/assets/js/main.js");

require("./ticker");
require("./social-share-button");

import videojs from "./video.min";
window.videojs = videojs;
require("./init_videojs");*/

//import "../vendor/frontend/taxiar/assets/js/vendor/jquery-3.6.0.min.js";

//import "../vendor/frontend/taxiar/assets/js/slick.min.js";
 
//import "../vendor/frontend/taxiar/assets/js/jquery.magnific-popup.min.js";
//import "../vendor/frontend/taxiar/assets/js/jquery.counterup.min.js";
//import "../vendor/frontend/taxiar/assets/js/imagesloaded.pkgd.min.js";
//import "../vendor/frontend/taxiar/assets/js/isotope.pkgd.min.js";
//import "../vendor/frontend/taxiar/assets/js/nice-select.min.js";
//import "../vendor/frontend/taxiar/assets/js/jquery.datetimepicker.min.js";

//import "../vendor/frontend/taxiar/assets/js/wow.min.js";

//import "../vendor/frontend/taxiar/assets/js/main.js";


import 'bootstrap-icons/font/bootstrap-icons.css'
/* Gsap */
//import "../vendor/frontend/vibetech/assets/js/bootstrap.bundle.min.js";
import "../vendor/frontend/vibetech/assets/js/gsap.min.js";
import "../vendor/frontend/vibetech/assets/js/ScrollTrigger.min.js";
import "../vendor/frontend/vibetech/assets/js/ScrollSmoother.min.js";
import SplitText from "../vendor/frontend/vibetech/assets/js/SplitText.min.js";

window.SplitText = SplitText;


/* Swiper Carousel JS */
//import "../vendor/frontend/vibetech/assets/js/swiper-bundle.min.js";

//import Swiper from  "../vendor/frontend/vibetech/assets/js/swiper-bundle.min.js";
import Swiper from  "../vendor/frontend/vibetech/assets/vendor/swiper/swiper-bundle.min.js";
window.Swiper = Swiper;

/* Magnific Popup JS */
//import "../vendor/frontend/vibetech/assets/js/jquery.magnific-popup.min.js";
/* Odometer JS */
import "../vendor/frontend/vibetech/assets/js/odometer.min.js";
import "../vendor/frontend/vibetech/assets/js/viewport.jquery.js";
/* Mean menu JS */
import "../vendor/frontend/vibetech/assets/js/jquery.meanmenu.min.js";
/* Main JS */
import "../vendor/frontend/vibetech/assets/js/main.js";

/* END */